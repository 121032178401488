import { commandRunner } from 'ygritte/commands/runner.js';
import { SendGoalCommand } from 'ygritte/commands/send-goal.js';
import { UpdateVisitorCommand } from 'ygritte/commands/update-visitor.js';
import { error, log } from 'ygritte/logging.js';

// Publishers can directly call this function by executing the window.ygritte
// function on their page.
export const runCommand = async (...args: Array<unknown>): Promise<void> => {
    log(`runCommand(${JSON.stringify(args)}})`);

    const methodName = args[0];
    if (typeof methodName !== 'string') return;

    switch (methodName) {
        case 'init': {
            // Init is already handled by CommandRunner constructor
            return;
        }

        case 'visitorUpdate':
        case 'props':
        case 'properties': {
            const command = new UpdateVisitorCommand(args[1]);
            await commandRunner.runCommand(command);
            return;
        }

        case 'goal': {
            const command = new SendGoalCommand(args[1]);
            await commandRunner.runCommand(command);
            return;
        }

        default: {
            error(`Unknown command: ${methodName}`);
        }
    }
};
