import { error } from 'ygritte/logging.js';
import { getStringProperty, isDictionary } from 'ygritte/util/object.js';
import type { Goal, GoalCategory, GoalProgress } from '~/domain/goal.js';
import { goalCategories, goalProgress } from '~/domain/goal.js';

function isGoalCategory(value: string): value is GoalCategory {
    return goalCategories.includes(value);
}

function isGoalProgress(value: string): value is GoalProgress {
    return goalProgress.includes(value);
}

// Parser function
export const parseGoal = (input: unknown): Goal | undefined => {
    if (!isDictionary(input)) {
        error(`Invalid goal: ${JSON.stringify(input)}`);
        return undefined;
    }

    const category = getStringProperty(input, 'category');
    const progress = getStringProperty(input, 'progress') ?? 'COMPLETE';
    const name = getStringProperty(input, 'name');

    if (!category) {
        error('Goal category is missing');
        return undefined;
    }

    if (!isGoalCategory(category)) {
        error(`Invalid goal category: ${category}`);
        return undefined;
    }

    if (!isGoalProgress(progress)) {
        error(`Invalid goal progress: ${getStringProperty(input, 'progress')}`);
        return undefined;
    }

    return {
        category,
        progress,
        clientName: name,
    };
};
