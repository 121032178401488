import { getStringProperty, isDictionary } from 'ygritte/util/object.js';
import type { VisitorData } from '~/domain/visitor-data.js';

// Parser function
export const parseVisitorData = (input: unknown): VisitorData | undefined => {
    if (!isDictionary(input)) return undefined;

    const visitorData: VisitorData = {
        area_of_study: getStringProperty(input, 'area_of_study'),
        concentration: getStringProperty(input, 'concentration'),
        military_affiliation: getStringProperty(input, 'military_affiliation'),
        intended_degree_level: getStringProperty(
            input,
            'intended_degree_level',
        ),
        learning_preference: getStringProperty(input, 'learning_preference'),
        how_likely_to_enroll: getStringProperty(input, 'how_likely_to_enroll'),
        highest_education_level: getStringProperty(
            input,
            'highest_education_level',
        ),
        rn_license_license: getStringProperty(input, 'rn_license_license'),
    };

    return visitorData;
};
