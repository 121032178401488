/*

A goal is an event that occurs on a client's website, that they want us to
track. By far away the most common event is a "clickout lead", i.e. a user has
converted on a client's website after clicking on one of our ads. These are
tracked as "REQUEST_INFO" goals.

In turn, we trigger pixels on Facebook, AdWords, and Campus Explorer to tell
them about the goal.

*/

export type GoalCategory = (typeof goalCategories)[number];
export const goalCategories = [
    'CONTENT',
    'REQUEST_INFO',
    'SCHEDULE_VISIT',
    'APPLICATION',
    'ENROLLMENT',
    'START',
    'AD',
] as const;

export type GoalProgress = (typeof goalProgress)[number];
export const goalProgress = ['BEGIN', 'STEP', 'COMPLETE'] as const;

export type Goal = {
    category: GoalCategory;
    progress: GoalProgress;
    clientName?: string;
};

export const getGoalCode = (goal: Goal): string => {
    const parts: Array<string> = [goal.category];
    if (goal.progress !== 'COMPLETE') parts.push(`:${goal.progress}`);
    if (goal.clientName) parts.push(`[${goal.clientName}]`);
    return parts.join('');
};
