import { InitializeCommand } from 'ygritte/commands/initialize.js';
import type { SendGoalCommand } from 'ygritte/commands/send-goal';
import type { UpdateVisitorCommand } from 'ygritte/commands/update-visitor';

/*

We have some rules around running our commands:

    1. Only run the initialize command once, and run it as soon as possible.

    2. Don't execute the updateVisitor or sendGoal commands until the
       initialize command has completed.

This class enforces these rules.

*/

class CommandRunner {
    private initializePromise: Promise<void>;

    constructor() {
        this.initializePromise = this.init();
    }

    async init() {
        if (!this.initializePromise) {
            const command = new InitializeCommand();
            this.initializePromise = command.execute();
        }
        await this.initializePromise;
    }

    async runCommand(
        command: UpdateVisitorCommand | SendGoalCommand,
    ): Promise<void> {
        await this.init();
        await command.execute();
    }
}

export const commandRunner = new CommandRunner();
