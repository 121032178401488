import { ygritteContext } from 'ygritte/context.js';
import { error, log, warn } from 'ygritte/logging.js';
import { parseVisitorData } from 'ygritte/parsers/visitor-data.js';
import { insertPixels } from 'ygritte/pixels.js';
import type { JsonFetchResult } from 'ygritte/util/fetch.js';
import { tryJsonPost } from 'ygritte/util/fetch.js';
import type { VisitorData } from '~/domain/visitor-data.js';

/*

Makes a call to the /api2/visitor endpoint if given valid visitor data.

*/

const apiPath = '/api2/visitor';

export class UpdateVisitorCommand {
    // eslint-disable-next-line no-useless-constructor
    constructor(public args: unknown) {}

    private getVisitorData(): VisitorData | undefined {
        const visitorData = parseVisitorData(this.args);

        if (!visitorData) {
            error(`Invalid visitor data: ${JSON.stringify(this.args)}`);
            return undefined;
        }

        // If all fields are undefined, return undefined
        if (Object.values(visitorData).every((value) => value === undefined)) {
            warn('Empty visitor data');
            return undefined;
        }

        return visitorData;
    }

    private async callApi(visitorData: VisitorData) {
        log(`calling ${apiPath}`);

        const fetchResult = await tryJsonPost({
            origin: ygritteContext.origin,
            path: apiPath,
            data: {
                token: ygritteContext.token,
                visitorData: visitorData,
                pageUrl: ygritteContext.pageUrl,
            },
        });

        return fetchResult;
    }

    private async handleResponse(fetchResult: JsonFetchResult) {
        if (!fetchResult.success) {
            error(`Error in call to ${apiPath}: ${fetchResult.error}`);
            return;
        }

        log(`Successfully called ${apiPath}`);

        await insertPixels(fetchResult);
    }

    async execute() {
        const visitorData = this.getVisitorData();
        if (!visitorData) return;
        const fetchResult = await this.callApi(visitorData);
        await this.handleResponse(fetchResult);
    }
}
