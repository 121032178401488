import { error } from 'ygritte/logging.js';

export function isDictionary(value: unknown): value is Record<string, unknown> {
    return (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value) &&
        Object.keys(value).every((key) => typeof key === 'string')
    );
}

export const getStringProperty = (
    record: Record<string, unknown>,
    key: string,
): string | undefined => {
    if (!(key in record)) return undefined;

    const value = record[key];

    if (typeof value !== 'string') {
        error(`Invalid value for property ${key}: ${value}`);
        return undefined;
    }

    return value;
};
